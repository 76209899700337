import {
  BuildEnv,
  EventTypeEnum,
  SourceTypeEnum,
  JumpTypeEnum,
  BuiltInEnum,
} from "../enums/index";
import {
  IEvent,
  IRouterOption,
  IRelationOption,
  IBuiltInOption,
} from "../interface/project";
import {
  getParamsValue,
  isParamsString,
  getParamsValueByKey,
  getConditionResult,
  runCustomJs,
  addUrlParams,
  fileDownload,
  loginOut,
  getHeadToUrlParams,
} from "../utils";
import { CurrentRequest } from "fusion-apis";
import { ElMessage, ElMessageBox } from "element-plus";

export function eventHooks(
  apps: any,
  rPageId?: number,
  router?: any,
  ENVPARAM?: string
) {
  const projectStore = apps?.projectStore;
  const paramsStore = apps?.paramsStore;
  const isReleased = apps?.genStore?.isReleased ?? false;

  // const genStore = apps?.genStore;
  let eventCompId = "";

  //获取事件参数
  function getParams(paramArr: any[], key: string, val: string): any {
    let param = {};
    paramArr.forEach((v) => {
      if (v[key]) {
        param[v[key]] = getParamsValue(paramsStore, v[val]);
      } else if (isParamsString(v[val])) {
        let paramItem = getParamsValueByKey(v[val]);
        param[paramItem.id] = getParamsValue(paramsStore, v[val]);
      }
    });
    return param;
  }

  function clearQueryUserInfo(params: any) {
    let newParams: any = {};
    Object.keys(params).forEach((v) => {
      if (!["WT-TOKEN", "WT-OPENID"].includes(v)) {
        newParams[v] = params[v];
      }
    });
    return newParams;
  }

  function setEventParam(ievent, pEventId, res) {
    let eventParams = paramsStore.getEvetParam(pEventId);
    let param = eventParams.find((item) => item.key == ievent.id);
    if (!param) {
      param = {
        key: ievent.id,
        label: ievent.name,
        type: "event",
        value: res,
      };
      eventParams = [...eventParams, ...[param]];
      paramsStore.addEvetParam(pEventId, eventParams);
    } else {
      param.value = res;
    }
  }

  //路由事件执行
  function executeRouteEvent(
    routerEvent: IRouterOption,
    currentPageId: string
  ): void {
    let { sourceType, params, page, appOption, url, jumpType, isInMenu } =
      routerEvent;
    let paramsObj = getParams(params, "name", "value"); //跳转参数

    const { id: pageId } = !currentPageId
      ? projectStore.currentPage
      : { id: currentPageId };

    //跳转页面
    if (
      sourceType == SourceTypeEnum.PAGE &&
      (page?.code || page?.route) &&
      (ENVPARAM == BuildEnv.RELEASED || ENVPARAM == BuildEnv.PREVIEW)
    ) {
      // genStore && genStore.setRouterKeepAlive(page.code, keepAlive);
      const currentRoute = router.currentRoute.value;
      if (ENVPARAM == BuildEnv.RELEASED) {
        let query = clearQueryUserInfo(currentRoute.query);
        if (jumpType == JumpTypeEnum.REPLACE) {
          if (isInMenu) {
            const pPageId = projectStore.getParentPageId(pageId);

            // let pRouter = currentRoute.matched[0];//多层嵌套时会找到最外层的菜单，导致菜单内跳转变成最外层菜单的页面
            let pRouter = currentRoute.matched[currentRoute.matched.length - 2];
            const pathInfo = pRouter.children.find((item) =>
              item.name.includes(page.code || page.route)
            );
            if (pathInfo) {
              router.push({
                path: pathInfo.path,
                query: Object.assign({}, query, paramsObj),
              });
            } else if (!!pPageId) {
              router.push({
                name: pPageId + "-" + (page.code || page.route),
                query: Object.assign({}, query, paramsObj),
              });
            }
          } else {
            router.push({
              name: page.code || page.route,
              query: Object.assign({}, query, paramsObj),
            });
          }
        } else {
          const routerPath = router.options.routes;
          let path = routerPath.filter(
            (item) => item.name == (page.code || page.route)
          )[0].path;
          const local = window.location;
          const jumpurl = local.origin + local.pathname + `#${path}`;
          window.open(addUrlParams(jumpurl, paramsObj),'_' + path);
        }
      } else {
        let query = clearQueryUserInfo(currentRoute.query);
        if (jumpType == JumpTypeEnum.REPLACE) {
          if (isInMenu) {
            const pPageId = projectStore.getParentPageId(pageId);
            console.log(pPageId);
            let pRouter = currentRoute.matched[0];
            const pathInfo = pRouter.children.find((item) =>
              item.name.includes(page.code)
            );
            if (pathInfo) {
              router.push({
                path: pathInfo.path,
                query: Object.assign({}, query, paramsObj),
              });
            } else if (!!pPageId) {
              router.push({
                name: pPageId + "-" + page.code,
                query: Object.assign({}, query, paramsObj),
              });
            }
          } else {
            query.pageId = page.code;
            router.push({
              path: "/preview",
              query: Object.assign({}, query, paramsObj, {
                timestampForRefresh: new Date().getTime(),
              }),
            }); //timestampForRefresh触发路由监听，解决路由无法监听问题
          }
        } else {
          const local = window.location;
          const jumpurl =
            local.protocol +
            "//" +
            local.host +
            `/#/preview?pageId=${page.code}`;
          window.open(addUrlParams(jumpurl, paramsObj),'_preview');
        }
      }
    } else if (
      sourceType == SourceTypeEnum.URL &&
      url &&
      (ENVPARAM == BuildEnv.RELEASED || ENVPARAM == BuildEnv.PREVIEW)
    ) {
      //链接跳转
      //参数拼接
      let paramsObj = getParams(params, "name", "value");
      let path = getParamsValue(paramsStore, url);
      if (jumpType == JumpTypeEnum.REPLACE) {
        window.location.href = addUrlParams(
          path,
          paramsObj
        );
      } else {
        let pathName = path.split('#');
        window.open(addUrlParams(path, paramsObj),'_' + (pathName[1] || pathName[0]));
      }
    } else if (
      sourceType == SourceTypeEnum.APP &&
      appOption?.app_address &&
      (ENVPARAM == BuildEnv.RELEASED || ENVPARAM == BuildEnv.PREVIEW)
    ) {
      let paramsObj = getParams(params, "name", "value");
      let _url = `${(window as any).BaseConfig.baseUrl}${
        appOption?.app_address
      }?${getHeadToUrlParams()}`;
      if (jumpType == JumpTypeEnum.REPLACE) {
        window.location.href = addUrlParams(_url, paramsObj);
      } else {
        let path = getParamsValue(paramsStore, _url);
        let pathName = path.split('#');
        window.open(addUrlParams(path, paramsObj),"_" + (pathName[1] || pathName[0]));
      }
    } else {
      //"请在预览或者发布后查看路由事件效果！
    }
  }

  //关联事件执行方法
  async function executeRelateEvent(
    relateEvent: IRelationOption,
    pageId: string,
    callBack?: any
  ) {
    let param = {};
    const { method, params, targetPage, targetId, graphOption } = relateEvent;
    if (method && params) {
      param = getParams(params, "name", "value");
    }
    let flag = true; //判断是否是数据源事件，如果不是需要执行callback方法
    let responsePage = targetPage ? targetPage : pageId;
    if (apps.compInstance[responsePage]) {
      if (method == "setShowState") {
        //触发组件的显示隐藏方法
        apps.compInstance[responsePage][targetId + "setShowState"](param);
      } else if (method == "refreshElementData") {
        flag = false;

        //触发组件的数据源刷新方法
        apps.compInstance[responsePage][targetId + "refreshElementData"](
          callBack
        );
      } else if (method == "saveForm") {
        // flag = false;
        //组件自定义的其他方法
        const compInstance = apps.compInstance[responsePage][targetId];
        compInstance[method] && await compInstance[method](param, callBack);
      } else if (!!graphOption) {
        const compInstance = apps.compInstance[responsePage][targetId];
        compInstance["listenEvent"] &&
          compInstance["listenEvent"](param, graphOption);
      } else {
        //组件自定义的其他方法
        const compInstance = apps.compInstance[responsePage][targetId];
        compInstance[method] && compInstance[method](param);
      }
    }
    if (flag && callBack) {
      callBack();
    }
  }

  //数据源事件执行方法
  async function executeDatasourceEvent(
    iEvent: IEvent,
    pEventId?: string,
    callBack?: any,
    callBackFunc?: any,
    eventCount?: any
  ) {
    const releasedRequestInstance = CurrentRequest.getInstance(
      (window as any).deployConfig.metaUrl,
      {}
    );
    const datasourceEvent = iEvent.datasource;
    const {
      dataUrl,
      requestMethod,
      params,
      contentType,
      isOperationTip,
      isFileDownload,
      isFileUpload,
      operationTitle,
      operationConfirm,
      operationCancel,
      fileName,
    } = datasourceEvent;
    const param = getParams(params, "name", "value");
    let responseType = {};
    if (isFileDownload) {
      responseType = { responseType: "blob" };
    } else if (isFileUpload && param.file) {
      Object.keys(param).forEach((v) => {
        v != "file" && param.file.append(v, param[v]);
      });
    }
    let dataParams = isFileUpload && param.file ? param.file : param;
    let triggerDatasource = async () => {
      await releasedRequestInstance[requestMethod.toLowerCase()](
        dataUrl,
        dataParams,
        {
          "Content-Type": contentType,
        },
        responseType
      ).then(
        (res: any) => {
          if (isFileDownload) {
            let _fileName = fileName ?? "数据.xlsx";
            fileDownload(_fileName, res);
          }
          datasourceEvent.isExecuteTrue = true;
          isOperationTip &&
            ElMessage({
              message: `${operationConfirm}`,
              type: "success",
            });
          if (eventCount) {
            eventCount.count++;
            if (eventCount.count == eventCount.len && callBackFunc) {
              callBackFunc(res);
            }
          }
          setEventParam(iEvent, pEventId, res);
          if (callBack) {
            callBack();
          }
        },
        (err) => {
          if (eventCount) {
            eventCount.count++;
            if (eventCount.count == eventCount.len && callBackFunc) {
              callBackFunc(err);
            }
          }
          setEventParam(iEvent, pEventId, err);
          datasourceEvent.isExecuteTrue = false;
          isOperationTip &&
            ElMessage({
              message: `${operationCancel}`,
              type: "error",
            });
        }
      );
    };
    if (isOperationTip && operationTitle) {
      ElMessageBox.confirm(`${operationTitle}`, "警告", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await triggerDatasource();
        })
        .catch(() => {
          console.log(`用户取消了此数据源（${dataUrl}）事件`);
        });
    } else {
      await triggerDatasource();
    }
  }

  /**
   * 脚本执行方法
   * @function
   * @author zhangsy 2023年05月03日 10:48:09
   * @param {*} param
   * @return {*} param
   * */
  function runCustomizeJs(customEvent, iEvent?: IEvent, pEventId?: string) {
    const { methodCode, params } = customEvent;

    if (methodCode) {
      const func = projectStore.getCustomJSByCode(methodCode);
      if (func) {
        const param = getParams(params, "name", "value");
        const res = runCustomJs(param, func);
        setEventParam(iEvent, pEventId, res);
      }
    }
  }

  function messageTipFuc(info) {
    const { type, content } = info;
    const message = getParamsValue(paramsStore, content);

    if (message) {
      ElMessage({
        type,
        message,
      });
    }
  }

  function downloadFile(fileInfo) {
    const { path, fileName } = fileInfo;

    // 使用 fetch 获取图片资源
    fetch(path)
      .then((response) => response.blob()) // 转换为 blob 对象
      .then((blob) => {
        // 创建一个 blob URL
        const url = window.URL.createObjectURL(blob);

        // 创建一个 <a> 元素
        const link = document.createElement("a");
        link.href = url; // 设置下载链接
        link.download = fileName || "downloaded-file"; // 设置下载的图片名称，默认为 'downloaded-image'

        // 触发 <a> 元素的点击事件
        link.click();

        // 释放 URL 对象
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("下载图片时出错:", error));

    // const filePath = path;
    // const link = document.createElement("a");
    // link.href = filePath;
    // link.download = fileName;
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  }

  //内置方法触发事件
  function executeBuiltinEvent(builtInEvent: IBuiltInOption): void {
    const {
      builtIn,
      modifyGlobalParams,
      createSessionParams,
      exec3DParams,
      messageTip,
      fileInfo,
      logingSetting,
    } = builtInEvent;
    try {
      switch (builtIn) {
        case BuiltInEnum.SIGNOUT:
          handleSignOut(logingSetting);
          break;
        case BuiltInEnum.MODIFYGLOBALPARAM:
          let param = getParams(modifyGlobalParams, "name", "value");
          paramsStore.modGlobalParamValue(param);
          break;
        case BuiltInEnum.CREATESESSION:
          let sessionParam = getParams(createSessionParams, "name", "value");
          if (isReleased) {
            modifySession(sessionParam);
          }
          break;
        case BuiltInEnum.RELOAD:
          location.reload();
          break;
        case BuiltInEnum.RETURNLAST:
          window.history.go(-1);
          break;
        case BuiltInEnum.EXE3D:
          handleExecute3D(exec3DParams);
          break;
        case BuiltInEnum.CLOSEDIALOG:
          closeDialog();
          break;
        case BuiltInEnum.MESSAGETIP:
          // let param = getParams(messageTip, "name", "value");
          messageTipFuc(messageTip);
          break;
        case BuiltInEnum.DOWNLOAD:
          // let param = getParams(messageTip, "name", "value");
          downloadFile(fileInfo);
          break;
        default:
          break;
      }
    } catch (e) {
      console.warn(`内置方法执行报错！报错内置方法编号${builtIn}!`);
    }
  }

  // 退出登录
  function handleSignOut(setting) {
    if (ENVPARAM == BuildEnv.RELEASED) {
      loginOut(paramsStore);
      if (setting != undefined && !setting.value) return;
      let hasLoginPath = PAGEEDITOR.$router
        .getRoutes()
        .some((item) => item.path === "/login");
      router.replace({
        path: hasLoginPath ? "/login" : "/",
      });
    }
  }

  // 执行3D方法
  function handleExecute3D(exec3DParams: any) {
    const { methodName, compValue } = exec3DParams;
    const value = getParamsValue(paramsStore, compValue);

    if ((window as any).vuplex) {
      (window as any).vuplex.postMessage({ api: methodName, args: value });
    }
  }

  // 创建SESSION变量
  function modifySession(param) {
    if (typeof param !== "object" || param === null) {
      console.error("Parameter must be an object.");
      return;
    }
    for (const key in param) {
      if (param.hasOwnProperty(key)) {
        const value = param[key];
        if (typeof key !== "string") {
          console.error("Key must be a string.");
          continue;
        }
        if (typeof value !== "string") {
          try {
            const stringValue = JSON.stringify(value);
            sessionStorage.setItem(key, stringValue);
          } catch (error) {
            console.error(`Failed to stringify value for key ${key}:`, error);
          }
        } else {
          sessionStorage.setItem(key, value);
          if (key == "WT-PROJECTID") {
            sessionStorage.setItem("isProjectIdModified", "true");
          }
        }
      }
    }
  }

  // 关闭弹框
  function closeDialog() {
    if (eventCompId) {
      const dialog = document
        .getElementById(eventCompId)
        ?.closest(".fusion-dialog");
      if (dialog) {
        //页面
        const pageId = dialog.getAttribute("page-id");
        const dialogId = dialog.getAttribute("id");
        window["PAGEEDITOR"]["compFunc"][`closeDialog_${pageId}_${dialogId}`]();
      } else {
        //iframe
        let parentWin = window.parent;
        let iframs =
          parentWin?.document.getElementsByClassName("fusion-dialog");
        for (let i = 0; i < iframs.length; i++) {
          const frame = iframs[i] as any;
          const pageId = frame.getAttribute("page-id");
          const dialogId = frame.getAttribute("id");
          parentWin["PAGEEDITOR"]["compFunc"][
            `closeDialog_${pageId}_${dialogId}`
          ]();
        }
      }
    }
  }

  async function eventListFuc(chainData, nextEvent, pEventId, id, pageId) {
    let flag = true;
    switch (nextEvent.type) {
      case EventTypeEnum.RELATION:
        flag = false;
        nextEvent.relationOption &&
          (await executeRelateEvent(nextEvent.relationOption, pageId, () => {
            changeChainPromise(chainData, nextEvent, pEventId, id, pageId);
          }));
        break;
      case EventTypeEnum.DATASOURCE:
        flag = false;
        nextEvent.datasource &&
          (await executeDatasourceEvent(nextEvent, pEventId, () => {
            changeChainPromise(chainData, nextEvent, pEventId, id, pageId);
          }));
        break;
      case EventTypeEnum.ROUTE:
        nextEvent.routerOption &&
          (await executeRouteEvent(nextEvent.routerOption, pageId));
        break;
      case EventTypeEnum.BUILTIN:
        nextEvent.builtInOption &&
          (await executeBuiltinEvent(nextEvent.builtInOption));
        break;
      case EventTypeEnum.CALCULATION:
        break; //计算脚本执行方法预留
      case EventTypeEnum.CUSTOM:
        nextEvent.customOption &&
          (await runCustomizeJs(nextEvent.customOption, nextEvent, pEventId));
        break; //自定义脚本执行方法预留
      default:
        break;
    }
    if (flag) {
      setTimeout(() => {
        changeChainPromise(chainData, nextEvent, pEventId, id, pageId);
      }, 200);
    }
  }

  function changeChainPromise(
    chainData,
    event,
    pEventId,
    eventId?,
    pageId?,
    isCatch?
  ) {
    const nextNodes = isCatch
      ? chainData[eventId || event.id].catchNode
      : chainData[eventId || event.id].nextNode;
    nextNodes &&
      nextNodes.forEach(async (id) => {
        let promiseInfo = chainData[id].promise.find(
          (item) => item.id == (eventId || event.id)
        );
        if (promiseInfo) {
          promiseInfo.isReady = true;
          if (chainData[id].promise.every((items) => items.isReady)) {
            if (chainData[id].event) {
              const nextEvent = chainData[id].event;
              eventListFuc(chainData, nextEvent, pEventId, id, pageId);
            } else if (chainData[id].condition) {
              let isTure = getConditionResult(
                chainData[id].condition.value || [],
                paramsStore
              );
              if (isTure) {
                const thenNode = chainData[id].thenNode;
                if (thenNode) {
                  thenNode.forEach(async (eventId) => {
                    const nextEvent = chainData[eventId].event;
                    eventListFuc(
                      chainData,
                      nextEvent,
                      pEventId,
                      eventId,
                      pageId
                    );
                  });
                }
              } else {
                changeChainPromise(
                  chainData,
                  chainData[id],
                  pEventId,
                  id,
                  pageId,
                  true
                );
              }
            }
          }
        }
      });
  }

  //事件触发入口
  function onEventMethods(
    eleId: string,
    eventKey: string,
    currentPageId: string,
    callBackFunc: any
  ): void {
    const { id: pageId } = !currentPageId
      ? projectStore.currentPage
      : { id: currentPageId };
    eventCompId = eleId || ("" as string);
    paramsStore.setCurrentPageId(pageId);
    let eventList = !currentPageId
      ? projectStore.currentPage.events
      : projectStore.getPageEventsById(pageId);
    if (eventList && eventList[eventCompId]) {
      const funcArr = eventList[eventCompId].filter(
        (item) => item.trigger == eventKey
      );
      const eventCount = {
        len: funcArr?.length ?? 0,
        count: 0,
      };
      eventCount.len &&
        funcArr.forEach(async (item: IEvent) => {
          eventCount.count++;
          if (item.isCondition && item.conditions) {
            let isTure = getConditionResult(item.conditions || [], paramsStore);
            if (!isTure) {
              callBackFunc && callBackFunc();
              return
            };
          }
          let isCallBack = true;
          switch (item.type) {
            case EventTypeEnum.ROUTE:
              callBackFunc && callBackFunc();
              item.routerOption &&
                item.routerOption &&
                (await executeRouteEvent(item.routerOption, currentPageId));
              break;
            case EventTypeEnum.RELATION:
              isCallBack = false;
              item.relationOption &&
                (await executeRelateEvent(item.relationOption, pageId, () => {
                  // 防止事件触发数据源请求之后，组件参数不是最新的
                  if (item.callChain?.eventChain) {
                    changeChainPromise(
                      item.callChain?.eventChain,
                      item,
                      item.id,
                      undefined,
                      pageId
                    );
                  }
                }));
              item.relationOption &&
                callBackFunc &&
                eventCount.count == eventCount.len &&
                callBackFunc();
              break;
            case EventTypeEnum.DATASOURCE:
              isCallBack = false;
              eventCount.count--;
              item.datasource &&
                (await executeDatasourceEvent(
                  item,
                  item.id,
                  () => {
                    // 防止事件触发数据源请求之后，组件参数不是最新的
                    if (item.callChain?.eventChain) {
                      changeChainPromise(
                        item.callChain?.eventChain,
                        item,
                        item.id,
                        undefined,
                        pageId
                      );
                    }
                  },
                  callBackFunc,
                  eventCount
                ));
              break;
            case EventTypeEnum.BUILTIN:
              item.builtInOption &&
                (await executeBuiltinEvent(item.builtInOption));
              break;
            case EventTypeEnum.CALCULATION:
              break; //计算脚本执行方法预留
            case EventTypeEnum.CUSTOM:
              item.customOption &&
                (await runCustomizeJs(item.customOption, item, item.id));
              break;
            default:
              break;
          }
          if (
            isCallBack &&
            callBackFunc &&
            eventCount.count == eventCount.len
          ) {
            callBackFunc();
          }
          if (item.callChain?.eventChain && isCallBack) {
            setTimeout(() => {
              changeChainPromise(
                item.callChain?.eventChain,
                item,
                item.id,
                undefined,
                pageId
              );
            }, 500);
          }
        });
    }
  }
  apps.onEventMethods = onEventMethods; //挂载事件触发方法
}
