<template>
  <div id="root" ref="pageRootRef" class="root">
    <div
      class="container"
      :style="pageStyle"
      style="position: relative; z-index: 11"
    >
      <RenderElementStatic
        v-for="item in pageData.elements"
        :key="item.id"
        :elements-instance="compInstance"
        :page-id="pageData.id"
        :element-data="item"
        :outer-layout="pageData.attribute.layout"
        :p-page-id="pPageId"
        :page-permission="pagePermission"
      >
      </RenderElementStatic>
    </div>
    <video
      v-if="pageAttribute?.background.type == 'video'"
      ref="bgVideo"
      class="bg-video-player"
      autoplay
      loop="loop"
      :src="videoSrc"
      style="position: absolute; z-index: 10; object-fit: fill; top: 0"
      :style="pageStyle"
    ></video>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  name: "PageTemplate",
});
</script>

<script lang="ts" setup>
import {
  watch,
  ref,
  defineComponent,
  nextTick,
  inject,
  computed,
  onMounted,
} from "vue";
import {
  IPage,
  eventHooks,
  getPageStyle,
  setPageCommon,
} from "fusion-core";
import { useRoute, useRouter } from "vue-router";
import RenderElementStatic from "fusion-component/src/render-element/render-element-static.vue";
import { useProjectStore } from "../stores/index";

const props = defineProps<{
  pageData: IPage;
  compInstance: {
    [propName: string]: any;
  };
}>();
const route = useRoute();
const projectStore = useProjectStore();
useGeneratePage(props.pageData, useRouter());

let pPageId = ref("");
const bgVideo = ref(),
  pageRootRef = ref();
const pageStyle = ref({});

let pagePermission = ref([]);

const pageAttribute = computed(() => props.pageData.attribute);
const videoSrc = computed(
  () =>
    window["deployConfig"].dfsUrlSuffix +
    pageAttribute?.value.background.value?.path
);

watch(
  route,
  (to) => {
    const matched = to.matched;
    const pageId = props.pageData.id;
    const routeInfo = to.matched.find((item) => {
      if (item.meta && item.meta.pPageID) {
        return item.name == item.meta.pPageID + "-" + pageId;
      }
    });
    if (routeInfo) {
      pPageId.value = routeInfo.meta.pPageID;
      projectStore.setParentPageId(pageId, pPageId.value);
    }
    const permission = localStorage.getItem("permission");
    if (permission && permission != 'undefined' && permission != 'null'){
      projectStore.setPermission(JSON.parse(permission).permissions || []);
    }
    pagePermission.value = projectStore.getPagePermission(pageId);
  },
  {
    deep: true,
    immediate: true,
  }
);

onMounted(async () => {
  await nextTick();
  if (bgVideo.value) {
    bgVideo.value.play();
  }
  pageStyle.value = getPageStyle(props.pageData,pageRootRef.value); //设置页面样式
});

//衍生页面处理方法
function useGeneratePage(pageData: any, router: any) {
  const apps = inject("apps") as Record<string, any>;
  const projectStore = apps?.projectStore;
  const paramsStore = apps?.paramsStore;
  projectStore.setPageInfo(pageData); //设置当前页面信息
  paramsStore.setCurrentPageId(pageData.id); //设置当前页面id
  //获取当前页面属性
  const pageAttr = pageData.attribute;
  setPageCommon(pageAttr); //处理页面属性值
  eventHooks(apps, pageData.id, router, "released");
}
</script>

<style scoped lang="less">
* {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
}

.root {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x:hidden;
  overflow-y:auto;
}
</style>
