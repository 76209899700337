<template>
  <div
    v-show="isElementShow"
    :id="elementData.id"
    :data-name="elementData.name"
    :data-code="elementData.code"
    :data-title="elementData.title"
    :class="{
      'element-wrap': true,
      'remove-event':
        !isElementShow || elementData.code.includes('FscAnimationCurtains'),
    }"
    :style="baseStyle(elementData)"
  >
    <RenderElementWrap
      :element-data="elementData"
      :elements-instance="elementsInstance"
      :page-id="pageId"
      :p-page-id="pPageId"
      :is-p-show="isPShow"
      :permission="compPermission"
      @change-is-element-show="changeIsElementShow"
    >
      <template #default="slotProps">
        <!-- 若当前是容器组件，内部包含其他组件，则继续渲染子组件 -->
        <RenderElementStatic
          v-for="(childElement, childIndex) in elementData.children"
          :key="childIndex"
          :element-data="childElement"
          :elements-instance="elementsInstance"
          :outer-layout="eleLayout"
          :page-id="pageId"
          :p-page-id="pPageId"
          :is-p-show="slotProps.isPShow"
          :page-permission="pagePermission"
        >
        </RenderElementStatic>
      </template>
    </RenderElementWrap>
  </div>
</template>

<script setup lang="ts">
import { IElement, getMaterialInitValue } from "fusion-core";
import { ref, toRefs, computed, onMounted } from "vue";
import RenderElementWrap from "./render-element-wrap.vue";
import { animateHook } from "./hook/useAnimateHook";

const props = defineProps<{
  elementData: any;
  elementsInstance: any; // 为了透传给render-element-wrap
  pageId: String; //页面id 为了透传给render-element-wrap
  pPageId: String; //页面id 为了透传给render-element-wrap
  outerLayout: string;
  isPShow:boolean;
  pagePermission?:any;
}>();
const { elementData, elementsInstance, outerLayout, pageId, pPageId } =
  toRefs(props);
const isElementShow = ref(true);
const isPShow = computed(() => props.isPShow ?? undefined);

const pagePermission = computed(()=>props.pagePermission ?? []);

const compPermission = computed(()=>pagePermission.value.find(item=>item.componentId == elementData.value.id)?.actions ?? []);

const animationAttr = computed(() => {
  return elementData.value.props.animationAttr; //组件动画属性
});
let animationArr = []; //组件动画组

// 获取组件基本样式
const baseStyle = computed(() => (element: IElement) => {
  const { baseAttr } = element.props;
  let style = {
    width:
      typeof baseAttr.width == "number"
        ? baseAttr.width + "px"
        : baseAttr.width,
    height:
      typeof baseAttr.height == "number"
        ? baseAttr.height + "px"
        : baseAttr.height,
    zIndex: baseAttr.zIndex ? baseAttr.zIndex : 1,
  };
  let absStyle = {};
  if (outerLayout.value === "absolute") {
    absStyle = {
      position: "absolute",
      left: baseAttr.left + "px",
      top: baseAttr.top + "px",
    };
  }

  //todo:因为目前宽度不支持百分比，所以只要是版心组件，宽度一律为100%
  if (element.code === "LcPageDepth" || element.code === "FscLayout") {
    style.width = "100%";
  }

  return { ...style, ...absStyle };
});

const eleLayout = computed(() => {
  const initValue = elementsInstance.value
    ? elementsInstance.value[elementData.value.code].initValue
    : getMaterialInitValue(elementData.value.code);
  let layout =
    elementData.value.code == "FscLayout" ||
    elementData.value.code == "FsmColumn"
      ? "flow"
      : elementData.value.props.extendAttr?.layout ||
        initValue.extendAttr?.layout;
  return layout;
});

onMounted(() => {
  //组件动画渲染
  let elementDom = document.getElementById(elementData.value.id);
  if (elementDom && animationAttr.value) {
    animationArr = animateHook().mergeAnimateData(animationAttr);
    animateHook().bindAnimate(elementDom, animationArr);
  }
});
function changeIsElementShow(isShow: boolean) {
  isElementShow.value = isShow;
}
</script>
