import { defineStore } from "pinia";
import type {
  IEventsParams,
  IEventsEleParams,
  IGlobalParam,
} from "fusion-core";
import {
  getGlobalParamsValueByType,
  getProjectCustomParams,
  setSessionItem,
  getCookie,
  setCookie,
  systemFuncs,
} from "fusion-core";

const loginUserInfo = {
  name: getCookie("name"),
  userName: getCookie("userName"),
  imgUrl: getCookie("imgUrl"),
};
export const useParamsStore = defineStore({
  id: "paramsPool",
  state: () => ({
    currentPageId: 0,
    userInfo: loginUserInfo,
    cusGlobalParams: [] as IGlobalParam[],
    globalParams: [] as IGlobalParam[],
    eleParams: {} as Record<string, IEventsEleParams>,
    eventParams: {} as Record<string, any>,
  }),
  getters: {
    //当前页面所有组件参数
    currentEleParams() {
      return this.getEleParamsById(this.currentPageId);
    },
  },
  actions: {
    //设置当前页面ID
    setCurrentPageId(pageId: number): void {
      if (pageId) {
        this.currentPageId = pageId;
      }
    },

    //获取指定页面的所有组件参数
    getEleParamsById(id: string): IEventsEleParams {
      let rst = {};
      if (id && this.eleParams[id]) {
        rst = this.eleParams[id];
      }
      return rst;
    },

    //设置转化全局变量结构并赋值
    setGlobalParams(projectGlobalParams: IGlobalParam[]): void {
      this.globalParams = projectGlobalParams;
      if (this.cusGlobalParams.length == 0) {
        getProjectCustomParams().then((res: any) => {
          if (res.code == "0" && res.data?.length) {
            this.cusGlobalParams = res.data.map((v) => {
              return {
                id: `cus_${v.code}`,
                label: v.name,
                pid: v.pid ? v.pid : "-1",
                type: "builtIn",
                value: v.value,
              };
            });
          }
          this.cusGlobalParams.push({
            id: "cus_APPID",
            label: "APPID",
            keyName: "",
            pid: "-1",
            type: "builtIn",
            value: window["deployConfig"].appId,
          });
          this.cusGlobalParams = this.cusGlobalParams.concat(systemFuncs);
        });
      }
    },

    //获取全局变量信息
    getGlobalParam(id: string): IGlobalParam {
      let param = {} as IGlobalParam;
      this.globalParams.forEach((v) => {
        if (v.id == id) {
          Object.assign(param, v);
          return true;
        }
      });
      return param;
    },

    //获取全局变量值
    getGlobalParamValue(id: string): any {
      let val;
      this.globalParams.forEach((v) => {
        if (v.id == id) {
          val = getGlobalParamsValueByType(v)?.value ?? "";
        }
      });
      return val;
    },

    //初始化用户相关的全局变量，用户登录后调用
    initGlobalUserInfo(userInfo: any) {
      let orgItem = (userInfo.orgItem || userInfo.organizationList) ?? [];
      this.userInfo = {
        name: userInfo.name,
        userName: userInfo.userName,
        imgUrl: userInfo.imgUrl || userInfo.userImgUrl,
        id: userInfo.id ?? "",
        orgItem: JSON.stringify(orgItem),
        orgId: orgItem.filter(item=>item.id).map(item => item.id)?.toString() ?? '',
        orgName: orgItem.filter(item=>item.nodeName).map(item => item.nodeName)?.toString() ?? '',
        orgPid: orgItem.filter(item => item.parentId).map(item => item.parentId)?.toString() ?? '',
        mainPostDepCode: orgItem.filter(item => item.mainPost).map(item => item.nodeCode)?.toString() ?? '',
        mainPostDepName: orgItem.filter(item => item.mainPost).map(item => item.nodeName)?.toString() ?? '',
        firstOrgId: orgItem.filter(item => item.id)[0]?.id ?? '',
        firstOrgName: orgItem.filter(item => item.nodeName)[0]?.nodeName ?? '',
      };
      userInfo.orgItem = this.userInfo.orgItem;
      let _this = this;
      this.globalParams.forEach((v) => {
        if ((v.pid == "userInfo" || v.pid == "orgInfo") && !["WT-TOKEN", "WT-OPENID"].includes(v.id)) {
          v.value = userInfo[v.id] || _this.userInfo[v.id];
          setSessionItem(v.id, JSON.stringify({value: v.value}));
          setCookie(v.id, v.value);
        }
      });
    },

    //修改全局变量值
    modGlobalParamValue(params: Record<string, string>): void {
      this.globalParams.forEach((v) => {
        if (v.id in params) {
          v.value = params[v.id];
          if (v.type == "SESSION") {
            setSessionItem(v.id, JSON.stringify({value: v.value}));
          }
        }
      });
    },

    //新增组件参数
    addEleParam(pageId: string, eleId: string, params: IEventsParams[]) {
      if (this.eleParams[pageId]) {
        this.eleParams[pageId][eleId] = params;
      } else {
        this.eleParams[pageId] = {
          [eleId]: params,
        };
      }
    },

    //获取组件所有参数
    getEleParam(pageId: string, eleId: string): IEventsParams[] {
      return this.eleParams[pageId][eleId] || [];
    },

    //获取组件具体参数
    getAppointEleParam(pageId: string, eleId: string, key: string): any {
      let val: any;
      let eleParam = this.getEleParam(pageId, eleId);
      eleParam.forEach((param) => {
        if (param && param.key == key) {
          val = param.value;
        }
      });
      if (val != 0 && !val) {
        val = "";
      }
      return val;
    },

    //新增组件参数
    addEvetParam(eveId: string, params: IEventsParams[], pageId?: string) {
      let id = pageId || this.currentPageId;
      if (this.eventParams[id]) {
        this.eventParams[id][eveId] = params;
      } else {
        this.eventParams[id] = {
          [eveId]: params,
        };
      }
    },

    //获取组件所有参数
    getEvetParam(eveId: string, pageId?: string): IEventsParams[] {
      let id = pageId || this.currentPageId;
      if (!this.eventParams[id]) {
        this.eventParams[id] = {
          [eveId]: [],
        };
      }
      return this.eventParams[id][eveId] ?? [];
    },
  },
});
