<template>
  <div id="app">
    <PreLoad :loading="isLoading" :page-data="pageData"></PreLoad>
    <div v-show="!isLoading" class="app-template">
      <keep-alive>
        <router-view v-if="isKeepAlive" :key="routerKey"></router-view>
      </keep-alive>
      <router-view v-if="!isKeepAlive" :key="routerKey"></router-view>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, watch, provide, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { CurrentRequest } from "fusion-apis";
import PreLoad from "../components/page-preload.vue";
import { useProjectStore, useParamsStore, useGenStore } from "../stores/index";
import { TinyEmitter } from "tiny-emitter";
import {
  IPage,
  loadIconfont,
  LoadTypeEnum,
  dataSourcesHooks,
  IGlobalParam,
  ICustomJS,
  ATUtils,
  GlobalParamsInstance,
  setSessionItem,
  queryPermissions,
  setCookie,
  getCookie,
  deleteCookie,
  getHeadsObject,
  getUserInfo,
} from "fusion-core";

interface IProjectGlobalData {
  projectGlobalParams: IGlobalParam[];
  projectCustomJS: ICustomJS[];
}

const props = defineProps<{
  projectData: IProjectGlobalData;
}>();

window["PAGEEDITOR"] = {
  compFunc: {},
  pageState: {
    isReleased: true,
  },
};

let GlobalInstance = GlobalParamsInstance.getInstance();

const win = window as any;
win.PAGEEDITOR.GlobalInstance = GlobalInstance;
const deployConfig = win.deployConfig;
const requestHeader = win.BaseConfig.requestHeader;
deployConfig?.fromApp && setSessionItem("WT-ISAGP", "AGP");

//全局挂载
const globalEmitter = new TinyEmitter();
win["PAGEEDITOR"]["globalEmitter"] = globalEmitter;
const requestInstance = CurrentRequest.getInstance(deployConfig.editUrl, {
  [requestHeader.PROJECTID]: deployConfig.projectId,
});
const releaseRequestInstance = CurrentRequest.getInstance(
  deployConfig.releasedUrl,
  {
    [requestHeader.PROJECTID]: deployConfig.projectId,
  }
);

// 根服务
const RootInstance = CurrentRequest.getInstance(deployConfig.rootUrl, {
  [requestHeader.PROJECTID]: deployConfig.projectId,
});

// 对象建模服务
const metaRequestInstance = CurrentRequest.getInstance(deployConfig.metaUrl, {
  [requestHeader.PROJECTID]: deployConfig.projectId,
});

// 挂载baseUrl的请求实例
const baseRequestInstance = CurrentRequest.getInstance(deployConfig.baseUrl, {
  [requestHeader.PROJECTID]: deployConfig.projectId,
});

// 路由表地址
const routerRequestInstance = CurrentRequest.getInstance(
  deployConfig.routerUrl,
  {
    [requestHeader.PROJECTID]: deployConfig.projectId,
  }
);

(window as any)["PAGEEDITOR"].requestList = {
  RootInstance,
  RequestInstance: requestInstance,
  BaseRequestInstance: baseRequestInstance,
  MetaRequestInstance: metaRequestInstance,
  ReleasedRequestInstance: releaseRequestInstance,
  RouterRequestInstance: routerRequestInstance,
};
loadIconfont(); //加载字体图标文件

const projectStore = useProjectStore();
const paramsStore = useParamsStore();
paramsStore.setGlobalParams(props.projectData.projectGlobalParams); //设置全局变量池
projectStore.setCustomJsArr(props.projectData.projectCustomJS); //设置全局变量池
const genStore = useGenStore();

const route = useRoute();
const router = useRouter();
const isKeepAlive = ref(false); //路由保持参数
const pageData = ref({} as IPage); //组件数据
const isLoading = ref(false); //是否有加载层
let loadingTimeout = null;
const compInstance = ref({});
const apps = ref({
  projectStore,
  paramsStore,
  genStore,
  compInstance,
  from: "app-template",
});

let routerKey = ref(new Date());

provide("apps", apps.value);
window["PAGEEDITOR"]["ATUtils"] = new ATUtils(apps.value);
window["PAGEEDITOR"]["$router"] = router;
dataSourcesHooks(apps.value); //数据源hook执行
//监听当前页面
watch(
  () => projectStore.currentPage,
  (newPage) => {
    if (newPage) {
      pageData.value = newPage;
      isLoading.value = newPage.attribute.loadType != LoadTypeEnum.NONE;
      if (pageData.value.elements.length == 0) {
        isLoading.value = true;
      }
    }
  },
  {
    deep: true,
    immediate: true,
  }
);

//监听当前路由
watch(
  () => route,
  (newVal) => {
    if (newVal?.name) {
      let pageArr = (newVal.name as string)?.split("-");
      let pageCode = pageArr.slice(-1)[0];

      // isKeepAlive.value = genStore.getRouterKeepAlive(pageCode); //获取当前页面保持状态
      projectStore.setCurrentPageId(pageCode); //设置当前页面ID
      let compInstance = {};
      pageArr &&
        pageArr.map((id) => {
          compInstance[id] = apps.value.compInstance[id];
        });
      apps.value.compInstance = compInstance; //不在当前页面的组件实例
      // routerKey.value = new Date;
    }
  },
  {
    deep: true,
    immediate: true,
  }
);

watch(
  () => router.currentRoute.value,
  async (val, oldVal) => {
    if (val.path == oldVal.path) {
      if (val.query[requestHeader.TOKEN] != oldVal.query[requestHeader.TOKEN] && val.query[requestHeader.OPENID] != oldVal.query[requestHeader.OPENID]){
        //需要重新给token和openid赋值
        val.query[requestHeader.TOKEN] && setCookie(requestHeader.TOKEN, val.query[requestHeader.TOKEN] + '');
        val.query[requestHeader.OPENID] && setCookie(requestHeader.OPENID, val.query[requestHeader.OPENID] + '');

        //相同路由下如果token和openid发生变化，重新获取用户信息再重绘
        await getUserInfo().then(res=>{
          paramsStore.initGlobalUserInfo(res.data);
          routerKey.value = new Date();
        })
      } else {
        routerKey.value = new Date();
      }
    }
  },
  {
    deep: true,
  }
);

//loading计数器监听
watch(
  () => genStore.loadingCount,
  (newVal) => {
    if (isLoading.value) {
      isLoading.value = newVal > 0; //若组件全局加载完成，则关闭loading
      if (!loadingTimeout) {
        loadingTimeout = setTimeout(() => {
          isLoading.value = false;
        }, 5000);
      }
    }
  },
  {
    deep: true,
  }
);

function savePermission() {
  // const permission = projectStore.permission;
  // localStorage.setItem("permission", JSON.stringify(permissionData.value));
  let oldUserId = getCookie("id");
  oldUserId && setCookie("oldUserId", oldUserId);
}

function getAuth() {
  const requestHeader = (window as any).BaseConfig.requestHeader;
  let isNoLoginToken = getCookie("isNoLoginToken");
  let headObj = getHeadsObject([requestHeader.TOKEN, requestHeader.OPENID]);
  if (
    !isNoLoginToken &&
    headObj &&
    headObj[requestHeader.TOKEN] &&
    headObj[requestHeader.OPENID]
  ) {
    return true;
  } else {
    return false;
  }
}

let permissionData = ref();

async function dealWithLoginInfo(loginInfo: any) {
  //设置全局变量用户信息相关值
  paramsStore.initGlobalUserInfo(loginInfo);

  // 记住密码
  // setUserInfo(ruleForm.username, ruleForm.password);
  await queryPermissions().then((result) => {
    if (result.data && result.data.login_status == '-1') return;

      // localStorage.setItem("allPrmssn", JSON.stringify(result.data));
      localStorage.setItem("permission", JSON.stringify(result.data));
      localStorage.setItem(
        "allPrmssn",
        JSON.stringify(result.data.permissionMenus || [])
      );
      projectStore.setPermission(result.data.permissions || []);
      permissionData.value = result.data;
    });
}

onMounted(async () => {
  window.addEventListener("beforeunload", savePermission);

  const permission = localStorage.getItem("permission");
  const oldUserId = getCookie("oldUserId");
  const userId = getCookie("id");
  if (permission && userId != "" && oldUserId == userId) {
    permissionData.value = JSON.parse(permission);

    localStorage.setItem(
      "allPrmssn",
      JSON.stringify(permissionData.value.permissionMenus || [])
    );
    projectStore.setPermission(permissionData.value.permissions || []);

    // 获取数据后立即删除 sessionStorage 中的数据
    // sessionStorage.removeItem("permission");
  } else if (getAuth() && userId != "") {
    await queryPermissions().then((result) => {
      if (result.data && result.data.login_status == '-1') return;

      // localStorage.setItem("allPrmssn", JSON.stringify(result.data));
      localStorage.setItem("permission", JSON.stringify(result.data));
      localStorage.setItem(
        "allPrmssn",
        JSON.stringify(result.data.permissionMenus || [])
      );
      projectStore.setPermission(result.data.permissions || []);
      permissionData.value = result.data;
    });
  } else if (getAuth() && oldUserId != ''){
    await getUserInfo().then(async res=>{
      await dealWithLoginInfo(res.data);
    })
  }
  deleteCookie("oldUserId");
});
</script>
<style lang="less">
.app-template {
  width: 100%;
  height: 100%;
}
</style>
