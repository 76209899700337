/*
 * @Date: 2023-10-10 14:15:58
 * @LastEditors: zhutj
 * @LastEditTime: 2024-08-26 18:56:50
 */
import { ref } from "vue";
import type { IMaterial } from "../interface/material";
import { PageElement, IElement } from "../interface/project";
import { deepClone, getBgStyle, changeAttr } from "../utils";
import { PageLayoutEnum } from "../enums";

// import { IElementInitValue } from "../interface/project";

// 装载组件
export async function loadElement(store, m: IMaterial | any, options?) {
  await store.load(m);
  return new Promise<IElement>(async (resolve) => {
    const ele = ref(PageElement.create()).value;
    ele.mId = m.elementId;
    ele.name = m.code + "-" + ele.id;
    ele.title = updateComponentIndex(store, m);
    ele.code = m.code;
    ele.formatCode = m.formatCode;
    ele.mVersion = m.version;
    ele.dataSourceExists = m.dataSourceExists;
    ele.kind = m.kind;
    ele.pid = null;
    ele.props = { baseAttr: getMaterialInitValue(m.code)["baseAttr"] };
    ele.fileName = m.fileName || "";
    ele.visibility = m.visibility

    // ele.initValue = getMaterialInitValue(m.code) as IElementInitValue;
    ele.events = getMaterialEvents(m.code);

    if (options && options.initValue) {
      ele.props = { ...ele.props, ...options.props };
      ele.initValue = { ...ele.initValue, ...options.initValue };
    }
    if (ele.code == "FscLayout") {
      ele.children = [];
      const child = {
        code: "FsmColumn",
        compId: null,
        elementId: -1,
        icon: "FsmColumn",
        name: "栅格组件",
        thumbnail: "dddd",
        visibility: 1,
        kind: 1,
      };
      for (let i = 0; i < 3; i++) {
        const childEle = await loadElement(store, child);
        ele.children.push(Object.assign({}, childEle, { pid: ele.id }));
      }
    } else if (ele.code == "FsmColumn" && m["children"]) {
      ele.children = m["children"];
    }
    resolve(ele);
  });
}

/**
 * 获得组件序号并保存至pinia中
 * @function
 * @author zhangsy 2023年11月07日 17:29:06
 * @param {*} store pinia信息
 * @param {*} info 组件信息
 * */
export function updateComponentIndex(store, info): string {
  let indexManager = store.currentPageIndexManager;
  let copyIndexManager = deepClone(indexManager);
  let code = info.code.replace(/[0-9]+/g, "");
  let title = "";
  if (info.title) {
    //兼容组件复制与组件初始化命名
    title = info.title.replace(/[0-9]+/g, "");
  }
  let keyName = Object.keys(copyIndexManager).find((item) =>
    item.includes(code)
  );
  let index;
  if (keyName) {
    index = copyIndexManager[keyName] + 1;
    copyIndexManager[keyName] = index;
    store.setCompIndexManager(keyName, index);
  } else {
    index = 1;
    copyIndexManager[code] = index;
    store.setCompIndexManager(code, index);
  }
  return (title ? title : info.name) + index;
}

export function getMaterialInitValue(code: string) {
  const eleAttrs = getMaterialEditorAttrs(code);
  window.PAGEEDITOR.compInstance[code].initValue = changeAttr(eleAttrs);
  return window.PAGEEDITOR.compInstance[code].initValue;
}
export function getMaterialRenderFun(material: IMaterial) {
  return window.PAGEEDITOR.compInstance[material.code].compInfo.render;
}
export function getMaterialEvents(code: string) {
  const EVENTS = deepClone(
    window.PAGEEDITOR.compInstance[code]?.compInfo.events
  );

  //组件必须抛出方法处理
  (EVENTS.methods || (EVENTS.methods = [])).push.call(EVENTS.methods, {
    label: "控制组件显示隐藏",
    key: "setShowState",
    params: [
      {
        title: "是否显示",
        name: "isShow",
        required: true,
        type: "input",
        value: "",
      },
    ],
  });
  return EVENTS;
}

export function getMaterialEditorAttrs(code: string) {
  return window.PAGEEDITOR.compInstance[code]?.editorAttrs;
}

export function getMaterialDefaultProps(material: IMaterial) {
  const props = getMaterialEditorAttrs(material.code);
  if (!props) {
    return {};
  }
  return Object.keys(props).reduce((pre, key) => {
    pre[key] = props[key].defaultValue;
    return pre;
  }, {});
}

//设置ico
export const setFavicon = (link) => {
  if (!link.startsWith("http")) {
    link = window["deployConfig"].dfsUrlSuffix + link;
  }
  let $favicon = document.querySelector('link[rel="icon"]') as any;
  if ($favicon !== null) {
    $favicon.href = link;
  } else {
    $favicon = document.createElement("link");
    $favicon.rel = "icon";
    $favicon.href = link;
    document.head.appendChild($favicon);
  }
};

export function setPageCommon(pageAttr) {
  if (pageAttr.icon && pageAttr.icon.path) setFavicon(pageAttr.icon.path); //设置页面icon
  if (pageAttr.isNameCustom) document.title = pageAttr.name; //设置页面标题
}

//初始化页面大小样式
export function getPageStyle(pageData, pageRootRef?): any {
  let style: any = {
    width: "100%",
    background: getBgStyle(pageData.attribute.background),
    backgroundSize: pageData.attribute.bgSize ?? "100% 100%",
    backgroundRepeat: pageData.attribute.bgRepeat ?? "no-repeat",
    overflowY: "auto"
  };
  const { attribute } = pageData;
  if (attribute.layout != PageLayoutEnum.PAGEDEPTH) {
    if (attribute.isPercentage) {
      style.width = "100%";
      style.height = "100%";
    } else {
      const pageWidth = pageData.attribute.canvasSize.width;
      const pageHeight = pageData.attribute.canvasSize.height;
      style.width = pageWidth + "px";
      style.height = pageHeight + "px";
      if (attribute.isScale) {
        //页面缩放
        const clientWidth = pageRootRef
          ? pageRootRef.clientWidth
          : document.body.clientWidth;
        const scaleRate =
          Math.ceil(Number(((clientWidth / pageWidth) * 10000).toFixed(5))) /
          10000;
        style.transform = `scale(${scaleRate})`;
        style.transformOrigin = `0% 0%`;
        style.overflow = "hidden";
      }
    }
    if (attribute.isHidden && pageRootRef) {
      pageRootRef.style.overflowY = "hidden";
      style.overflowY = "hidden";
    } else if (!attribute.isScroll && pageRootRef) {
      //不显示滚动条
      // document.getElementsByTagName("body")[0].className += "hide-scroll";
      pageRootRef.className += " hide-scroll";
    }
  }
  return style;
}
