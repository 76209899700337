import { createApp, ref } from "vue";
import permissionTooltip from "./permission-tooltip.vue";

// 创建一个全局div容器用于显示文本
const tooltipDiv = document.createElement("div");

// 将全局div元素添加到body中
document.body.appendChild(tooltipDiv);

// 创建一个全局变量用于存储ToolTip实例
let tooltipApp: any;

//tooltip是否显示
export const tooltipDisplay = ref("none");

//给myToolTip组件传递text
export const tooltipText = ref<string>("");

//给myToolTip组件传递位置
export const finalLeft = ref<number>(0);
export const finalTop = ref<number>(0);

function useEleDirective(vm: any) {
  if (vm) {
    vm.directive("active", {
      mounted(el, bingding) {
        updatedEleParams(bingding);
      },
      updated(el, bingding) {
        updatedEleParams(bingding);
      },
    });

    vm.directive("permission", {
      mounted(el, bingding) {
        checkUerPermission(el, bingding);
      },
      unmounted(el: any) {
        if (tooltipText.value) {
          // 移除事件监听器
          el.removeEventListener("mouseenter", el._showTooltip);
          el.removeEventListener("mousemove", el._showTooltip);
          el.removeEventListener("mouseleave", el._hideTooltip);

          // 卸载实例
          tooltipApp.unmount();

          // 移除全局div
          tooltipDiv.remove();
          tooltipText.value = "";
        }
      },
    });
  }
}

function updatedEleParams(bingding: any): void {
  let id = bingding.instance.$.attrs.id || bingding.instance.$.props.id;

  let _params = bingding.value;
  if (_params) {
    const apps = bingding.instance.$.provides["apps"];
    const paramsStore = apps?.paramsStore;
    const pageId =
      bingding.instance.$.attrs["page-id"] || apps?.projectStore.currentPage;
    let eleonentParams = paramsStore.eleParams;
    if (eleonentParams) {
      paramsStore.addEleParam(pageId, id, _params);
    }
  }
}

function checkUerPermission(el: any, bingding: any): void {
  const elKey = bingding.value?.key;
  const permission = bingding.value?.permission;
  let tipText = "";
  const showTooltip = (event: any) => {
    // 显示
    tooltipDisplay.value = "block";

    // 如果已有实例则更新数据，否则创建实例
    if (!tooltipApp) {
      // 没有实例则创建并挂载
      tooltipApp = createApp(permissionTooltip);
      tooltipApp.mount(tooltipDiv);
    }
    tooltipText.value = tipText;

    // 设置位置不遮挡
    finalLeft.value = event.clientX + 10;
    finalTop.value = event.clientY + 15;
  };

  // 鼠标移出时隐藏tooltip
  const hideTooltip = () => {
    tooltipDisplay.value = "none";
  };

  if (elKey && permission?.length) {
    const elPermission = permission.find((v) => v.component_key == elKey);
    if (elPermission?.show_type == "invisible") {
      // 如果用户没有权限，移除绑定的元素
      el.parentNode && el.parentNode.removeChild(el);
    } else if (elPermission && elPermission?.show_type != "visible") {
      el.onclick = null;
      el.firstChild && (el.firstChild.style["pointer-events"] = "none");
      tipText = elPermission?.invisible_tip ?? null;
    }
  }

  // 添加事件监听器
  if (tipText) {
    el.addEventListener("mouseenter", showTooltip);
    el.addEventListener("mousemove", showTooltip);
    el.addEventListener("mouseleave", hideTooltip);
  }
}

export { useEleDirective };
