//挂载在全局的工具文件
import { IAxiosHead } from "packages/apis/src";
import { logoutApi } from "../service";
import { getParamsValue, getGlobalParamsValueByType } from "./params";
import { getUrlParam } from "./utils";
import { ElMessage } from "element-plus";

export class ShowMessagePop {
  private showFlag:boolean;

  constructor() {
    this.showFlag = true;
  }

  // 获取单例实例
  static getInstance() {
    if (!this["instance"]) {
      this["instance"] = new ShowMessagePop();
    }
    return this["instance"];
  }

  public showMessage(msgObj:any){
    if (this.showFlag){
      this.showFlag = false;
      ElMessage(msgObj);
      setTimeout(()=>{
        this.showFlag = true;
      },3000)
    }
    return
  }
}
export class ATUtils {
  protected projectStore: any;

  protected paramsStore: any;

  private RESHEEADER = window["BaseConfig"].requestHeader;

  constructor(apps: any) {
    if (apps) {
      this.projectStore = apps.projectStore;
      this.paramsStore = apps.paramsStore;
    }
  }

  //token
  public setToken(newToken) {
    setCookie(this.RESHEEADER.TOKEN, newToken);
  }
  public getToken() {
    return getCookie(this.RESHEEADER.TOKEN);
  }
  public deleteToken() {
    deleteCookie(this.RESHEEADER.TOKEN);
  }

  //openId
  public setOpenId(newOpenId) {
    setCookie(this.RESHEEADER.OPENID, newOpenId);
  }
  public getOpenId() {
    return getCookie(this.RESHEEADER.OPENID);
  }
  public deleteOpenId() {
    deleteCookie(this.RESHEEADER.OPENID);
  }

  //projectId
  public setPorjectId(newProjectId) {
    setSessionItem(this.RESHEEADER.PROJECTID, newProjectId);
  }

  //appDomain
  public setAppDomain(appDomain?) {
    setSessionItem(this.RESHEEADER.APPDOMAIN, appDomain);
  }
  public getAppDomain() {
    return getSessionItem(this.RESHEEADER.APPDOMAIN);
  }

  //openId
  public setAppClient(appId) {
    setSessionItem(this.RESHEEADER.APPID, appId);
  }
  public getAppClient() {
    return getSessionItem(this.RESHEEADER.APPID);
  }

  public setAppName(APPNAME) {
    setSessionItem(this.RESHEEADER.APPNAME, APPNAME);
  }
  public getAppName() {
    return getSessionItem(this.RESHEEADER.APPNAME);
  }

  public getNoLogin() {
    return getSessionItem("noLogin");
  }

  //根据全局变量占位符获取全局变量值
  public getGlobalParamsValue(key: string) {
    if (key) {
      return getParamsValue(this.paramsStore, key);
    } else {
      return key;
    }
  }

  public isLogin(): boolean {
    const token = this.getToken();
    const openId = this.getOpenId();
    const isNoLoginToken = getSessionItem("isNoLoginToken");
    if (!!token && !!openId && !isNoLoginToken) {
      return true;
    } else {
      return false;
    }
  }

  //根据全局变量ID获取全局变量值
  public getGlobalParamsValueById(id: string) {
    if (id) {
      // 全局变量
      let currentGlobalParam = this.paramsStore.globalParams.find(
        (item) => item.id == id
      );
      !currentGlobalParam &&
        (currentGlobalParam = this.paramsStore.cusGlobalParams.find(
          (item) => item.id == id
        ));
      return getGlobalParamsValueByType(currentGlobalParam)?.value ?? "";
    } else {
      return id;
    }
  }

  //修改全局变量
  public modGlobalParamValueById(params: Record<string, string>) {
    if (params) {
      this.paramsStore.modGlobalParamValue(params);
    }
  }

  public showMessage(msgObj:any){
   const msgInstance = ShowMessagePop.getInstance();
   msgInstance.showMessage(msgObj);
  }
}

export function setCookie(
  key: string,
  value: string | number | boolean,
  path = location.pathname,
  expires?: Date | number,
  domain?: string
): void {
  const requestHeader = (window as any).BaseConfig.requestHeader;
  const projectId = (window as any).deployConfig.projectId;
  let appPath = path == location.pathname ? location.pathname.split('page/' + projectId)[0] : path;
  const userInfo = [requestHeader.TOKEN, requestHeader.OPENID];
  let cookieText = key + "=" + encodeURIComponent(value) + ";path=" + appPath;
  if (expires) {
    if (expires instanceof Date) {
      cookieText += ";expires=" + expires.toUTCString();
      cookieText += ";expires=" + expires.toUTCString();
    } else {
      let date = new Date();
      date.setDate(date.getDate() + expires);
      cookieText += ";expires=" + date.toUTCString();
      cookieText += ";expires=" + date.toUTCString();
    }
  } else if (userInfo.includes(key)) {
    let date = new Date();
    date.setDate(date.getDate() + 7);
    cookieText += ";expires=" + date.toUTCString();
    cookieText += ";expires=" + date.toUTCString();
  }
  if (domain) {
    cookieText += ";domain=" + domain;
  }
  document.cookie = cookieText;
}

export function getCookie(name) {
  const reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  const arr = document.cookie.match(reg);
  let res = "";
  if (arr && arr[2] && arr[2] != "null" && arr[2] != "undefined") {
    res = arr[2];
  }
  return decodeURIComponent(res);
}

export function deleteCookie(key) {
  // setCookie(key, "");
  // 清除cookie里的字段，防止重复
  let name = key + "=";
  let ca = document.cookie.split(';');
  let cookieStr = "";
  for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      if (c.indexOf(name) == 0) {
          document.cookie = c + ';expires=' + new Date(0).toUTCString()
      } else {
          cookieStr += c;
          cookieStr += ";";
      }
      document.cookie = cookieStr;
  }
}

/**
 * 获取sessionStorage
 * @param {string} key 要从cookie中获取的关键字
 *
 * @param {string} undecode 是否不需要解码 （默认解码）
 */
export function getSessionItem(key: string, undecode?: boolean) {
  let item = sessionStorage.getItem(key);
  if (!item || item == "null" || item == "undefined") {
    item = "";
  }
  return !undecode ? decodeURIComponent(item) : item;
}

/**
 * 设置获取sessionStorage
 * @param {string} key  要存入session中的的关键字
 * @param {string} value 要存入session中的的值
 */
export function setSessionItem(key, value) {
  sessionStorage.setItem(key, encodeURIComponent(value));
}

export function getLocalStorageItem(key: string, undecode?: boolean) {
  let o = localStorage[key];
  if (typeof o === "undefined") {
    return null;
  } else {
    return !undecode ? decodeURIComponent(o) : o;
  }
}

export function setLocalStorageItem(key, value) {
  localStorage[key] = value == null ? "" : value;
}

export function deleteLocalstorage(key: string) {
  window.localStorage.removeItem(key);
}

export function getHeadToUrlParams(array?) {
  const requestHeader = (window as any).BaseConfig.requestHeader;
  const userInfo = [requestHeader.TOKEN, requestHeader.OPENID];
  if (!array) {
    array = [requestHeader.TOKEN, requestHeader.OPENID, requestHeader.APPID];
  }
  const urlParams = array.map((item) => {
    if (userInfo.includes(item)) {
      return item + "=" + getCookie(item);
    } else {
      return item + "=" + getSessionItem(item);
    }
  });
  return urlParams.join("&");
}

//除用户信息相关的token和openid，其他都从session取值，且传过来的请求头信息不一定都在requestHeader记录，可能存在自定义化的
export function getHeadsObject(array: string[]) {
  const requestHeader = (window as any).BaseConfig.requestHeader;
  const userInfo = [requestHeader.TOKEN, requestHeader.OPENID];
  let headObj: any = {};
  array.forEach((item) => {
    if (userInfo.includes(item)) {
      getCookie(item) && (headObj[item] = getCookie(item));
    } else {
      getSessionItem(item) && (headObj[item] = getSessionItem(item));
    }
  });
  return headObj;
}

export function deleteHeadInfo(headArr) {
  const requestHeader = (window as any).BaseConfig.requestHeader;
  const userInfo = [requestHeader.TOKEN, requestHeader.OPENID];
  headArr.forEach((item) => {
    if (userInfo.includes(item)) {
      deleteCookie(item);
    } else {
      sessionStorage.removeItem(item);
    }
  });
}

//存储编辑器/衍生登录信息
export function setLoginInfo(info: IAxiosHead) {
  const requestHeader = (window as any).BaseConfig.requestHeader;
  const userInfo = [requestHeader.TOKEN, requestHeader.OPENID];
  for (var item in info) {
    if (info[item] != null) {
      if (userInfo.includes(item)) {
        setCookie(item, info[item]);
      } else {
        setSessionItem(item, info[item]);
      }
    }
  }
}

export function getHeadItemByKey(key: string) {
  const requestHeader = (window as any).BaseConfig.requestHeader;
  const userInfo = [requestHeader.TOKEN, requestHeader.OPENID];
  if (userInfo.includes(key)) {
    return getUrlParam(key) || getCookie(key);
  } else {
    return getUrlParam(key) || getSessionItem(key);
  }
}

//退出登录
export function loginOut(paramsStore) {
  logoutApi().then(() => {
    const requestHeader = (window as any).BaseConfig.requestHeader;
    deleteHeadInfo([
      requestHeader.TOKEN,
      requestHeader.OPENID,
      requestHeader.APPNAME,
      requestHeader.APPDOMAIN,
      requestHeader.APPID,
    ]);

    //清空主要登录信息
    paramsStore.initGlobalUserInfo({
      name: "",
      userName: "",
      imgUrl: "",
    });

    deleteLocalstorage("localUserInfo");
  });
}
