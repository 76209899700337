/**
 * @ Description:资源管理器接口
 * @ Modified time: 2024-12-26 19:03:54
 */
import { IResData } from "fusion-apis";
import { ContentTypeEnum } from "../enums/httpEnum";

const appType = 1;

// 文件夹文件分类树
export function getPageFolder(): IResData {
  return (window as any)["PAGEEDITOR"]?.requestList.RequestInstance.get(
    "/v1/resourceManage/listFolderTree",
    {
      type: appType,
    },
    {
      "Content-Type": ContentTypeEnum.JSON,
    }
  );
}

//根据文件夹code获取子文件和子文件夹
export function getPageList(params): IResData {
  return (window as any)["PAGEEDITOR"]?.requestList.RequestInstance.get(
    "/v1/resourceManage/listFiles",
    {
      type: appType,
      ...params,
    },
    {
      "Content-Type": ContentTypeEnum.JSON,
    }
  );
}

/**
 * 保存文件夹
 * @param type 文件类型
 * */
export function saveFileFolder(params): any {
  return (window as any)["PAGEEDITOR"]?.requestList.BaseRequestInstance.post(
    "/dfs/folder/save",
    params,
    {
      "Content-Type": ContentTypeEnum.FORM_URLENCODED,
    }
  );
}

/**
 * 获取图片文件夹
 * @param type 文件类型
 * */
export function getFileFolder(): any {
  return (window as any)["PAGEEDITOR"]?.requestList.BaseRequestInstance.get(
    "/dfs/folder/folderTree",
    {},
    {
      "Content-Type": ContentTypeEnum.JSON,
    }
  );
}

//获取图片文件夹
export function getFileList(params): any {
  return (window as any)["PAGEEDITOR"]?.requestList.BaseRequestInstance.get(
    "/dfs/file/listFile",
    params,
    {
      "Content-Type": ContentTypeEnum.JSON,
    }
  );
}

//上传文件
export function uploadFile(params): any {
  return (window as any)["PAGEEDITOR"]?.requestList.BaseRequestInstance.post(
    "/dfs/uploadImg",
    params,
    {
      "Content-Type": ContentTypeEnum.FORM_DATA,
    }
  );
}

//删除文件
export function removeFile(params): any {
  return (window as any)["PAGEEDITOR"]?.requestList.BaseRequestInstance.delete(
    "/dfs/deleteImg",
    params,
    {
      "Content-Type": ContentTypeEnum.JSON,
    }
  );
}

//删除文件夹
export function removeFileFolder(params): any {
  return (window as any)["PAGEEDITOR"]?.requestList.BaseRequestInstance.get(
    "/dfs/folder/delete",
    params,
    {
      "Content-Type": ContentTypeEnum.JSON,
    }
  );
}

//文件快传
export function quickUpload(params): any {
  return (window as any)["PAGEEDITOR"]?.requestList.BaseRequestInstance.get(
    "/dfs/file/quickUpload",
    params,
    {
      "Content-Type": ContentTypeEnum.JSON,
    }
  );
}

//上传分片上传
export function uploadFileBySlice(params): any {
  return (window as any)["PAGEEDITOR"]?.requestList.BaseRequestInstance.post(
    "/dfs/file/uploadFileBySlice",
    params,
    {
      "Content-Type": ContentTypeEnum.FORM_DATA,
    }
  );
}

//获取虚拟页面
export function getVirtRoutes(params): any {
  return (window as any)["PAGEEDITOR"]?.requestList.RequestInstance.get(
    "/v1/route/listVirtRoutes",
    params,
    {
      "Content-Type": ContentTypeEnum.JSON,
    }
  );
}

//获取图标
export function getIconfont(params): any {
  return (window as any)["PAGEEDITOR"]?.requestList.RequestInstance.get(
    "/v1/iconFont/download/iconfont",
    params,
    {
      "Content-Type": ContentTypeEnum.JSON,
    }
  );
}

//获取图标
export function getIconJson(path): any {
  return (window as any)[
    "PAGEEDITOR"
  ]?.requestList.RequestInstanceDfsUrlSuffix.get(
    path,
    {},
    {
      "Content-Type": ContentTypeEnum.JSON,
    }
  );
}
export function getJsonFile(path: string): any {
  return fetch(path);
}

//获取虚拟路由文件夹
export function getRouteFolder(param?: any) {
  return (window as any)["PAGEEDITOR"]?.requestList.RequestInstance.get(
    "/v1/routeFolder/tree",
    param,
    {
      "Content-Type": ContentTypeEnum.JSON,
    }
  );
}

//获取虚拟路由文件夹
export function getRouteList(param: any) {
  return (window as any)["PAGEEDITOR"]?.requestList.RequestInstance.get(
    "/v1/routeFolder/listPage",
    param,
    {
      "Content-Type": ContentTypeEnum.JSON,
    }
  );
}

//保存图片上传记录
export function saveImgRecord(params): any {
  return (window as any)["PAGEEDITOR"]?.requestList.RequestInstance.post(
    "/v1/resources/sava/picture",
    params,
    {
      "Content-Type": ContentTypeEnum.FORM_URLENCODED,
    }
  );
}

//删除图片上传记录
export function deleteImgRecord(params): any {
  return (window as any)["PAGEEDITOR"]?.requestList.RequestInstance.get(
    "/v1/resources/delete/picture",
    params,
    {
      "Content-Type": ContentTypeEnum.JSON,
    }
  );
}

//获取绘图文件夹
export function getGraphFolder(): any {
  return (window as any)["PAGEEDITOR"]?.requestList.BaseRequestInstance.get(
    "/graph/graphFolder/folderTree",
    {},
    {
      "Content-Type": ContentTypeEnum.JSON,
    }
  );
}

//获取绘图文件
export function getGraphList(params): any {
  return (window as any)["PAGEEDITOR"]?.requestList.BaseRequestInstance.get(
    "/graph/graphFolder/listFile",
    params,
    {
      "Content-Type": ContentTypeEnum.JSON,
    }
  );
}

//获取gis文件夹
export function getGisFolder(params): any {
  return (window as any)[
    "PAGEEDITOR"
  ]?.requestList.ThirdUrlRequestInstance.post(
    "/atgis/api/GisFolder/folderTree",
    params,
    {
      "Content-Type": ContentTypeEnum.FORM_DATA,
    }
  );
}

//获取gis文件夹
export function getGisList(params): any {
  return (window as any)[
    "PAGEEDITOR"
  ]?.requestList.ThirdUrlRequestInstance.post(
    "/atgis/api/GisFolder/listFile",
    params,
    {
      "Content-Type": ContentTypeEnum.FORM_DATA,
    }
  );
}

//获取报表文件夹
export function getGridFolder(): any {
  return (window as any)["PAGEEDITOR"]?.requestList.BaseRequestInstance.get(
    "/report/reportFolder/folderTree",
    {},
    {
      "Content-Type": ContentTypeEnum.JSON,
    }
  );
}

//获取绘图文件
export function getGridList(params): any {
  return (window as any)["PAGEEDITOR"]?.requestList.BaseRequestInstance.get(
    "/report/reportFolder/listFile",
    params,
    {
      "Content-Type": ContentTypeEnum.JSON,
    }
  );
}

//获取报表文件夹
export function getWordFolder(): any {
  return (window as any)["PAGEEDITOR"]?.requestList.BaseRequestInstance.get(
    "/report/textFolder/listFolder",
    {},
    {
      "Content-Type": ContentTypeEnum.JSON,
    }
  );
}

//获取报告文件
export function getWordList(params): any {
  return (window as any)["PAGEEDITOR"]?.requestList.BaseRequestInstance.get(
    "/report/textFolder/listFile",
    params,
    {
      "Content-Type": ContentTypeEnum.JSON,
    }
  );
}

//获取文件夹通用
export function getFormFolder(params): any {
  return (window as any)["PAGEEDITOR"]?.requestList.MetaRequestInstance.get(
    "/tools/v1/getFolderList",
    params,
    {
      "Content-Type": ContentTypeEnum.JSON,
    }
  );
}

//查询表单分类列表(树)
//params:isContainForm-默认false，是包含表单节点
export function getFormClassList(params?: any) {
  return (window as any)["PAGEEDITOR"]?.requestList.MetaRequestInstance.get(
    `/tools/form/formClassify/v1/list`,
    params,
    {
      "Content-Type": ContentTypeEnum.JSON,
    }
  );
}

//获取文件通用
 export function getFormList(params): any {
   return (window as any)['PAGEEDITOR']?.requestList.MetaRequestInstance.get(
     "/tools/v1/getFileList",
     params,
     {
       "Content-Type": ContentTypeEnum.JSON,
     }
   )
 }

export function commonGet(url, params): any {
  return (window as any)["PAGEEDITOR"]?.requestList.MetaRequestInstance.get(
    url,
    params,
    {
      "Content-Type": ContentTypeEnum.JSON,
    }
  );
}

//获取项目级全局变量
export function getProjectCustomParams(query?: string) {
  return (window as any)["PAGEEDITOR"]?.requestList.MetaRequestInstance.get(
    "infra/front/loadGlobalVariable",
    {
      query: query ? query : "",
    },
    {
      "Content-Type": ContentTypeEnum.JSON,
    }
  );
}

//退出登录
export function logoutApi() {
  return (window as any)["PAGEEDITOR"]?.requestList.MetaRequestInstance.get(
    "/infra/v1/user/client/logout",
    "",
    {
      "Content-Type": ContentTypeEnum.FORM_URLENCODED,
    }
  );
}

/**
 * 获取文件列表
 * @author zhutj 2024年08月28日 09:21:46
 * @param param
 * @return param
 * */
 export function getFolderList(params) {
  return (window as any)['PAGEEDITOR']?.requestList.MetaRequestInstance.get(
    '/meta/model/exploer/getFolderList',
    params,
    {
      'Content-Type': ContentTypeEnum.JSON,
    }
  )
}

// 类-获取用户权限信息
export function queryPermissions() {
  return (window as any)["PAGEEDITOR"]?.requestList.MetaRequestInstance.get(
    "permission/queryPermissions",
    '',
    {
      "Content-Type": ContentTypeEnum.JSON,
    }
  );
}

// 类-获取用户权限信息
export function getUserInfo() {
  return (window as any)["PAGEEDITOR"]?.requestList.MetaRequestInstance.get(
    "/infra/v1/user/info",
    '',
    {
      "Content-Type": ContentTypeEnum.JSON,
    }
  );
}
