<!--
 * @Descripttion: 权限提示
 * @Author: leiwb
-->
<template>
  <div ref="tooltipRef" class="tooltip" :style="tipStyle">
    {{ tooltipText }}
  </div>
</template>

<script lang="ts" setup>
import { ref,computed, onMounted, watch, nextTick } from "vue";
import {
  tooltipText,
  finalLeft,
  finalTop,
  tooltipDisplay,
} from "./compDirective";

//获取dom
const tooltipRef = ref<HTMLElement>();

// 存储容器的宽度和高度
const divWidth = ref<number>();
const divHeight = ref<number>();

// 计算视窗的宽度和高度
const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;

onMounted(() => {
  // 获取容器的宽度和高度
  if (tooltipRef.value) {
    divHeight.value = tooltipRef.value.offsetHeight;
    divWidth.value = tooltipRef.value.offsetWidth;
  }
});

watch(tooltipText, () => {
  // 监听text的变化，当text变化时，重新获取容器的宽度和高度
  nextTick(() => {
    if (tooltipRef.value) {
      divHeight.value = tooltipRef.value.offsetHeight;
      divWidth.value = tooltipRef.value.offsetWidth;
    }
  });
});

// 计算位置
const posleft = computed(() => {
  if (divWidth.value && finalLeft.value) {
    if (finalLeft.value + divWidth.value > windowWidth) {
      return windowWidth - divWidth.value - 8; // 调整left值，使其不超出右边界
    } else {
      return finalLeft.value;
    }
  }
  return finalLeft.value;
});
const posTop = computed(() => {
  if (divHeight.value && finalTop && divHeight.value && finalTop.value) {
    if (finalTop.value + divHeight.value > windowHeight) {
      return windowHeight - divHeight.value - 8; // 调整top值，使其不超出下边界
    } else {
      return finalTop.value;
    }
  }
  return finalTop.value;
});

const tipStyle = computed(() => {
  return {
    display: tooltipDisplay.value,
    left: `${posleft.value}px`,
    top: `${posTop.value}px`,
  };
});
</script>

<style lang="less">
.tooltip {
  position: fixed;
  z-index: 9999;
  user-select: none;
  padding: 3px 5px;
  background: rgba(0,0,0,.5);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(103, 103, 103, 0.2);
  color:white;
  border-radius: 8px;
}
</style>
