// 组件属性类型枚举
export enum attrEnum {

    // 输入框
    INPUT = 'input',

    // 输入框
    TEXTAREA = 'textarea',

    // 数字输入框
    INPUT_NUMBER = 'input_number',

    // 下拉选
    SELECT = 'select',

    //颜色选择器
    COLORSELECT='color_select',

    //颜色多选器
    COLORSELECTARR='color_select_arr',

    //单选框
    RADIO = "radio",

    //滑块
    SWITCH='switch',

    // 文件选择器
    SLIDER='slider',

    //字体选择器
    FONTFAMILY='fontFamily',

    //背景选择器
    BACKGROUND='background',

    // 页面选择器
    PAGESOIRCE='pageSource',

    // 图片选择器
    PICTURESOIRCE='pictureSource',

    // 图标选择器
    ICONSOIRCE='iconSource',

    // 绘图选择器
    GRAPHSOIRCE='graphSource',

    // gis选择器
    GISSOIRCE='gisSource',

    // 报表选择器
    GRIDSOIRCE='gridSource',

    // 报告选择器
    WORDSOIRCE='wordSource',

    // 条件选择器
    CONDITION='condition',

    // 表单选择器
    FROMSOURCE='formSource',

    // 表单选择器
    PROCESSFORMSOURCE='processFormSource',

    // 随机id生成器
    GUID='guid',

    // 表单设置
    FORMSETTING='formSetting',

    //类路径选择器
    CLASSPATH='classPath',

    //树形选择器
    TREESELECT='treeSelect',

    //类关联关系选择器
    CLASSRELATION = 'classRelation',

    //组织选择器
    ORGSELECT='orgSelect',

    //条件选择器
    JSONEDITOR = 'jsonEditor',

    //类关联选择
    RELATIONSHIP = "relationship",

    //获得接口field值
    GETFIELD = "getField",

    //app选择器
    APPSTORE = 'appstore',

    // 文件选择器
    FOLDERSTORE='folderSource',

}

// 页面加载方式
export enum LoadTypeEnum {
    NONE = 'none', //无
    MASK = 'mask', //遮罩层
    SKELETON = 'skeleton' //骨架屏
}

// 页面布局类型
export enum LayoutEnum {
    FLOW = '流式布局', //流式布局
    ABSOLUTE = '绝对定位',

    // PAGEDEPTH = '版心排版'
}

export enum PageLayoutEnum{
    FLOW = 'flow', //流式布局
    ABSOLUTE = 'absolute',
    PAGEDEPTH = 'pageDepth'
}

// 单位枚举
export enum unitValueEnum{
    PX='px',
    PERCENT='%'
}

export enum backgroundTypeEnum{
    IMG='img',
    COLOR='color'
 }
